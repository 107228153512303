<template>
    <div>
        <!-- 商家端 -->

        <div v-if="mode == '1'" class="download-wrap" :style="{ background: 'url(' + bgUrl + ') 0% 0% / 100% 100% no-repeat rgb(255, 255, 255)' }">
            <div class="download-body">
                <div class="download-body-logo" :style="{ 'margin-left': mode != 0 ? '30px' : '0' }">
                    <img :src="appData.download_logo && appData.download_logo.content" width="49" height="49" alt="" />
                    <div class="title">
                        <div class="tip1">iHK</div>
                        <div class="tip2">
                            {{ mode != 0 ? appData.download_tips_merchant && appData.download_tips_merchant.content : appData.download_tips && appData.download_tips.content }}
                        </div>
                    </div>
                </div>
                <img
                    :src="mode == 0 ? appData.download_image && appData.download_image.content : appData.download_image_merchant && appData.download_image_merchant.content"
                    width="326"
                    height="592"
                    alt=""
                />
            </div>

            <div class="download-box">
                <div class="download-btn" v-if="type != 0">
                    <div v-if="mode != 0" :class="['btn', { active: index == 0 }, { en: !isZH }]" @click="downloadMerchant">
                        {{ $t('m.merchant_download') }}
                    </div>
                    <div v-else :class="['btn', { active: index == 1 }, { en: !isZH }]" @click="downloadUser">
                        {{ $t('m.client_download') }}
                    </div>
                </div>
                <div class="download-btn" v-else>
                    <template v-if="mode != 0">
                        <div v-if="mode != 0" :class="['btn', { active: index == 0 }, { en: !isZH }]" @click="downloadMerchant">
                            {{ $t('m.merchant_download') }}
                        </div>
                    </template>
                    <template v-else>
                        <!-- <div v-else :class="['btn', { active: index == 0 }, { en: !isZH }]" id="downloadButton"> -->
                        <div :class="['btn', { active: index == 0 }, { en: !isZH }]" @click="downloadUser">
                            {{ $t('m.client_download') }}
                        </div>
                        <div style="width: 100px; margin-left: 10px" :class="['btn', { active: index == 0 }, { en: !isZH }]" id="downloadButton">
                            {{ $t('m.open') }}
                        </div>
                    </template>
                </div>
            </div>

            <action-sheet v-model="show" :actions="actions" @select="onSelect" :round="false" :close-on-click-action="true" />
        </div>
        <!-- 用戶端 -->
        <!-- <div v-else class="download-wrap" :style="{ background: 'url(' + bgUrl + ') 0% 0% / 100% 100% no-repeat rgb(255, 255, 255)' }" > -->
        <div v-else class="download-wrap">
            <img class="u-download-bg" :src="bgUrl" alt="" />
            <div class="u-download-body">
                <div class="u-download-body-logo">
                    <img :src="appData.download_logo && appData.download_logo.content" width="49" height="49" alt="" />
                    <div class="title">
                        <div class="tip1">iHK</div>
                        <div class="tip2">
                            {{ appData.download_tips && appData.download_tips.content }}
                        </div>
                    </div>
                </div>
                <div class="u-download-body-title">
                    {{ appData.donwload_title && appData.donwload_title.content }}
                </div>
                <!-- <img
                    :src="mode == 0 ? appData.download_image && appData.download_image.content : appData.download_image_merchant && appData.download_image_merchant.content"
                    width="326"
                    height="592"
                    alt=""
                /> -->
            </div>
            <!-- <div class="u-download-bottom" :style="{ background: 'url(' + btnBgUrl + ') 0% 0% / 100% 100% no-repeat ' }"> -->
            <div class="u-download-bottom">
                <img class="u-download-bottom-bg" :src="btnBgUrl" alt="" />
                <div class="download-box">
                    <div class="u-download-btn" v-if="type != 0">
                        <div v-if="mode != 0" :class="['btn', { active: index == 0 }, { en: !isZH }]" @click="downloadMerchant">
                            {{ $t('m.merchant_download') }}
                        </div>
                        <div v-else :class="['btn', { active: index == 1 }, { en: !isZH }]" @click="downloadUser">
                            {{ $t('m.client_download') }}
                        </div>
                    </div>
                    <div class="u-download-btn" v-else>
                        <template v-if="mode != 0">
                            <div v-if="mode != 0" :class="['btn', { active: index == 0 }, { en: !isZH }]" @click="downloadMerchant">
                                {{ $t('m.merchant_download') }}
                            </div>
                        </template>
                        <template v-else>
                            <!-- <div v-else :class="['btn', { active: index == 0 }, { en: !isZH }]" id="downloadButton"> -->
                            <div :class="['btn', { active: index == 0 }, { en: !isZH }]" @click="downloadUser">
                                {{ $t('m.client_download') }}
                            </div>
                            <div style="width: 100px; margin-left: 10px; display: none" :class="['btn', { active: index == 0 }, { en: !isZH }]" id="downloadButton">
                                {{ $t('m.open') }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <action-sheet v-model="show" :actions="actions" @select="onSelect" :round="false" :close-on-click-action="true" />
            <!-- <data android:scheme="android" android:host="ihk" android:pathPrefix="/open"/> -->
        </div>
    </div>
</template>

<script>
import ActionSheet from 'vant/lib/action-sheet';
import Toast from 'vant/lib/toast';
import 'vant/lib/action-sheet/style';
import 'vant/lib/toast/style';
import { mapGetters } from 'vuex';
export default {
    components: {
        ActionSheet,
        Toast,
    },
    computed: {
        ...mapGetters({
            appData: 'app/ichargeDownloadData',
        }),
        isZH() {
            return this.$i18n.locale == 'zh_HK';
        },
        bgUrl() {
            return (
                (this.mode == '1'
                    ? this.appData.m_download_bg_image && this.appData.m_download_bg_image.content
                    : this.appData.download_bg_image && this.appData.download_bg_image.content) || ''
            );
        },
        btnBgUrl() {
            return (this.appData.download_btn_image && this.appData.download_btn_image.content) || '';
        },
        iosMerchant() {
            return (this.appData.iosMerchant && this.appData.iosMerchant.content) || '';
        },
        iosUser() {
            return (this.appData.iosUser && this.appData.iosUser.content) || '';
        },
        googleMerchant() {
            return (this.appData.googleMerchant && this.appData.googleMerchant.content) || '';
        },
        googleUser() {
            return (this.appData.googleUser && this.appData.googleUser.content) || '';
        },
    },
    data() {
        return {
            mode: 0, //0为用户端，1为商户端
            type: -1, //0安卓，1苹果
            show: false,
            index: -1,
            androidMerchant: {
                path: null,
                id: null,
            },
            anroidUser: {
                path: null,
                id: null,
            },
            actions: [
                { name: '本地下載', type: 0 },
                { name: 'Google play下載', type: 1 },
            ],
            systemType: '',
            envId: 2, // env环境，默认生态产適用環境：0-測試；1-灰度；2-生產
        };
    },
    methods: {
        onSelect(item) {
            let url = item.type === 0 ? (this.index === 0 ? this.androidMerchant.path : this.androidUser.path) : this.index === 0 ? this.googleMerchant : this.googleUser;
            if (!url) return Toast(this.$t('m.no_source'));
            if (item.type === 1) {
                this.aTagClick(url);
            } else {
                this.iframeDownload(url);
            }

            if (item.type === 0) {
                this.downloadCount(this.index === 0 ? this.androidMerchant.id : this.androidUser.id);
            }
        },
        downloadMerchant() {
            this.index = 0;
            if (this.type === 1) {
                if(!this.iosMerchant) return Toast(this.$t('m.no_source'));
                this.aTagClick(this.iosMerchant);
            } else {
                this.show = true;
            }
        },
        downloadUser() {
            this.index = 1;
            if (this.type === 1) {
                if(!this.iosUser) return Toast(this.$t('m.no_source'));
                this.aTagClick(this.iosUser);
            } else {
                this.show = true;
            }
        },
        //模拟a标签点击事件
        aTagClick(url) {
            var a = document.createElement('a');
            a.href = url;
            a.click();
        },
        //iframe下载
        iframeDownload(url) {
            var iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = url;
            document.getElementsByTagName('body')[0].appendChild(iframe);
        },
        async downloadCount(id) {
            // 用户点击了下载按钮 应该调统计下载次数接口了
            const data = await this.$axios.get(`/admin/app/downloadStatistics?id=${id}`);
            if (data.code === 200) {
                console.log('下载统计成功');
            }
        },
        // 获取用户代理系统类型
        getMobile() {
            const ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf('iphone') != -1) {
                this.type = 1;
                return 'ios';
            } else if (ua.indexOf('android') != -1) {
                this.type = 0;
                this.getUrl();
                // this.createScriptTag();
                return 'android';
            } else if (ua.indexOf('windows') != -1) {
                return 'windows';
            } else {
                return 'none';
            }
        },

        // 获取环境  envIdentification:0-测试；1-灰度；2-生产
        getEnvId() {
            return new Promise((resolve) => {
                let env = process.env.NODE_ENV || 'tests',
                    envId = 0;
                switch (env) {
                    case 'canary':
                        envId = 1;
                        break;
                    case 'tests':
                        envId = 0;
                        break;
                    case 'production':
                        envId = 2;
                        break;
                }
                resolve(envId);
            });
        },

        //安卓獲取URL
        //商户
        async getMerchantLink() {
            let data = await this.$axios.get(`/admin/app/android/version/${2}/${this.envId}`);
            if (data.code === 200) {
                this.androidMerchant = {
                    path: data.result.path,
                    id: data.result.id,
                };
                return data.result;
            } else {
            }
        },
        //用户
        async getUserLink() {
            let data = await this.$axios.get(`/admin/app/android/version/${1}/${this.envId}`);
            if (data.code === 200) {
                this.androidUser = {
                    path: data.result.path,
                    id: data.result.id,
                };
                return data.result;
            } else {
            }
        },
        async getUrl() {
            await this.getMerchantLink();
            await this.getUserLink();
        },
        createScriptTag() {
            var obj = {
                id: this.$route.query.id,
            };
            let script2 = document.createElement('script');
            script2.type = 'text/javascript';
            script2.text = `var data =${JSON.stringify(
                obj
            )};new OpenInstall({appKey: "jm230s",onready: function () {this.schemeWakeup();var m = this,button = document.getElementById("downloadButton");button.onclick = function () {m.wakeupOrInstall({timeout:0});return false;}} }, data);`;
            document.getElementsByTagName('head')[0].appendChild(script2);
        },
    },
    async created() {
        this.envId = await this.getEnvId();
        this.systemType = this.getMobile();
    },
    mounted() {
        try {
            let mode = this.$route.query.mode || 0;
            let busId = this.$route.query.bus_id || 0;
            // 必須是用戶端下載頁才去調用URL schema
            if (this.systemType && this.systemType === 'android' && mode == 0) this.aTagClick(`ihk://ms_power?bus_id=${busId}`);
        } catch (err) {}
    },
};
</script>

<style lang="scss" scoped>
.download-wrap {
    // background: #fff url(~@/assets/imgs/download/download.png) no-repeat;
    background-size: cover;
    position: relative;
    height: 100vh;
}
.download-body {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    z-index: 99;
    padding: 5vh 0;
    &-logo {
        margin-bottom: 20px;
        display: flex;
        .title {
            margin-left: 12px;
            height: 49px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .tip1 {
                font-size: 25px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #333330;
            }
            .tip2 {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
            }
        }
    }
}
.download-box {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10%;
    bottom: 10%;
    width: 80%;
    z-index: 100;
    position: fixed;
}
.download-btn {
    display: flex;
    justify-content: center;
    .btn {
        padding: 0 15px;
        height: 46px;
        box-shadow: 0px 5px 20px 0px rgba(10, 125, 134, 0.1);
        border-radius: 47px;
        border: 2px solid #00c9d9;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        color: #00c9d9;
    }
    .en {
        font-size: 12px;
    }
    .active {
        background: #00c9d9;
        color: #ffffff;
    }
}
.u-download-bg {
    width: 100%;
    position: absolute;
    z-index: -1;
}
.u-download-body {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 99;
    padding: 5vh 0;
    &-logo {
        margin-bottom: 20px;
        display: flex;
        .title {
            margin-left: 12px;
            height: 49px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            .tip1 {
                font-size: 25px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #ffffff;
            }
            .tip2 {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
            }
        }
    }
    &-title {
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
    }
}
.u-download-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 28vh;
    width: 100%;
    .download-box {
        display: flex;
        justify-content: center;
        align-items: center;
        left: 15%;
        bottom: 4%;
        width: 70%;
        z-index: 100;
        position: fixed;
        .u-download-btn {
            display: flex;
            justify-content: center;
            width: 100%;
            .btn {
                width: 100%;
                padding: 0 15px;
                height: 46px;
                box-shadow: 0px 5px 20px 0px rgba(255, 170, 17, 0.1);
                border-radius: 8px;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ffaa11;
                color: #ffffff;
            }
            .en {
                font-size: 12px;
            }
            // .active {
            //     background: #00c9d9;
            //     color: #ffffff;
            // }
        }
    }
    &-bg {
        position: absolute;
        z-index: -1;
        width: 100%;
    }
}
</style>
